<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Pending Orders</h5>

        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="mytable"
                 class="table table-hover table-bordered table-condensed table-columned"
                 data-search="false"
                 data-pagination="true"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-field="id" data-class="d-none" data-sortable="true">Code</th>
              <th data-formatter="runningFormatter" data-width="75">S.No</th>
              <th data-field="doc_no" data-sortable="true" data-width="99">Order No</th>
              <th data-field="status" data-width="60" data-sortable="true">Status</th>
              <th data-field="doc_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
              <th data-field="ref_no" data-sortable="true" data-width="75">Ref No</th>
<!--              <th data-field="ref_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Ref Date</th>-->
              <th data-field="buyer" data-sortable="true">Buyer</th>
              <th data-field="rolls" data-formatter="indianFormat4Value" data-width="120" data-sortable="true" data-align="right">Ordered</th>
              <th data-field="baled" data-formatter="indianFormat4Value" data-width="120" data-sortable="true" data-align="right">Baled</th>
              <th data-field="balance" data-formatter="indianFormat4Value" data-width="120" data-sortable="true" data-align="right">Balance</th>
              <th data-field="remarks" data-sortable="true">Remarks</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>

      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <!--<a href="#" class="dropdown-item" ><i class="icon-puzzle"></i>Breakup</a>-->
          <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
        </div>
      </div>
      <!-- End of Context Menu -->


      <FocusTrap>
        <vmodal name="saleorder_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="80%" height="80%" :draggable="true" >
          <SaleOrderForm v-if="voucher.id > 0" v-bind:myvoucher="voucher" v-on:saleorder_window_closed="closeModal"  v-on:sale_order_saved="loadData"></SaleOrderForm>
        </vmodal>
      </FocusTrap>


    </div>
  </FocusTrap>
</template>

<script>

import SaleOrderForm from '@/views/fms/saleorder/SaleOrderForm.vue'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'SaleOrderBalanceView',
  store,
  components: {
    SaleOrderForm,
    FabButton,
    moment
  },
  data () {
    return {
      voucher: JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2018,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"agent_id":0,"agent":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"itemtype":1,"remarks":"","list":[]}'),
      mytable: {},
      tableData: [],
      mytabulator: {},
      isActive: false,
      from_date: moment().add(-7, 'days').format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      qualities : [],
      buyers : [],
      quality_id:0,
      buyer_id:0,
    }
  },
  created () {

  },
  mounted () {
    const self = this

    this.$data.mytable = $('#mytable');
    this.$data.mytable.bootstrapTable();

    $('[data-toggle="popover"]').popover();
    $('#mytable').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {

        let id = $(row.children('*')[0]).text();
        let code = $(row.children('*')[2]).text();

        if ($(e.target).text() === 'Refresh') {
          self.loadData();
        } else if ($(e.target).text() === 'Print') {
          self.printPreview(id);
        } else if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        } else if ($(e.target).text() == 'Remove') {
          self.removeDocument(id);
        }

      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#mytable>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });

    $('[data-action=remove]').click(function (e) {
      $('#saleorder_window').modal('hide');
    });
    // self.loadBuyers();
    // self.loadQualities();

    self.loadData();

    // this.showFilterWindow();
  },
  methods: {
    closeModal () {
      this.$modal.hide('saleorder_window');
      $('#saleorder_window').modal('hide');
    },
    afterfilterwindow_visible(){
      $('#txtfrom').focus();
    },
    showFilterWindow(){
      this.$data.isActive = true;
      this.$modal.show('filter-window');
    },
    printPreview (id) {

      const self = this;
      self.$modal.show('viewer_window')

      self.$data.voucher = self.$data.mytable.bootstrapTable('getRowByUniqueId', id)

      self.$data.voucher.doc_date = self.$data.voucher.doc_date.slice(0, 10).split('-').join('-');


    },
    showModal () {
      $('#saleorder_window').modal('hide');
    },
    clear () {
      this.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2018,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"agent_id":0,"agent":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"itemtype":1,"remarks":"","list":[]}');
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }


      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2018,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"agent_id":0,"agent":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"itemtype":1,"remarks":"","list":[]}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/saleorder/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {

            self.$data.voucher = resp.data;
            self.$data.voucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
            self.$data.voucher.ref_date = moment(self.$data.voucher.ref_date).format('YYYY-MM-DD');
            self.$modal.show('saleorder_window');
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    removeDocument (id) {
      const self = this;

      self.$data.voucher.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.voucher)
      }

      swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete this!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/saleorder`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('saleorder_window');
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            )
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error', timer:3000 });
          })
        }
      })

    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('');
      self.$modal.hide('filter-window');
      self.$modal.hide('saleorder_window');

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      })

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');

      let filters = `from=${self.from_date}&upto=${self.upto_date}&quality_id=${self.quality}&buyer_id=${self.buyer_id}`;


      fetch(`${process.env.VUE_APP_ROOT_API}api/saleorder/pendings?${filters}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {

          if (_.isArray(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 });
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $(self.$data.mytable).unblock();
      });
    },
    loadBuyers(){
      const self = this;

      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      //fetch the Ledgers
      self.$data.buyers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/buyers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if(_.isArray(resp.data)){
            self.$data.buyers = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      });
    },
    loadQualities () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.qualities = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.qualities = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
  }
}
</script>

<style scoped>
  .modal-full {
    min-width: 100%;
    margin: 0;
  }

  .modal-full .modal-content {
    min-height: 100vh;
  }

  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
